import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import useWindowSize from '../hooks/useWindowSize';
import '../style/services.sass';
import PageIntroSection from '../components/PageIntroSection';
import SalientFeatures from '../components/SalientFeatures';
import VerticalCarousel from '../components/VerticalCarousel';

// Main Page Component
const SubServicesPageTemplate = ({ pageIntroData, salientData, carouselData }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isCarouselVisible, setIsCarouselVisible] = useState(false);
  // const [selectedFeatureData, setSelectedFeatureData] = useState(carouselData[currentSlide]);

  const handleFeatureClick = (index) => {
    setCurrentSlide(index);
    setIsCarouselVisible(true);
    console.log("index",index)
    // setSelectedFeatureData(carouselData[index]);
    setTimeout(() => {
      window.scrollTo({
        top: document.querySelector('.carousel-container').offsetTop,
        behavior: 'smooth'
      });
    }, 0);
  };
  

  return (
    <div className="services-page">
      <PageIntroSection pageIntro={pageIntroData} />
      <SalientFeatures title={salientData.title} features={salientData.features} onFeatureClick={handleFeatureClick} />
      {isCarouselVisible && (
      <VerticalCarousel 
        items={carouselData} 
        currentSlide={currentSlide} 
        onSlideChange={setCurrentSlide} />
       )}
    </div>
  );
};
SubServicesPageTemplate.propTypes = {
  pageIntroData: PropTypes.object.isRequired,
  salientData: PropTypes.shape({
    title: PropTypes.arrayOf(
      PropTypes.shape({
        fragment: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
      })
    ).isRequired,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        caption: PropTypes.string.isRequired,
        image: PropTypes.object.isRequired,
        backgroundColor: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  carouselData: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      button: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
      screen1: PropTypes.object.isRequired,
      screen2: PropTypes.object.isRequired,
      screen3: PropTypes.object.isRequired,
      backgroundImage: PropTypes.object.isRequired,
      backgroundColor: PropTypes.string.isRequired,
    })
  ).isRequired,
};



// SubServicesPageTemplate.propTypes = {
//   subServices: PropTypes.shape({
//     commercialAnalytics: PropTypes.shape({
//       pageIntroData: PropTypes.shape({
//         desktop: PropTypes.shape({
//           title: PropTypes.arrayOf(
//             PropTypes.shape({
//               fragment: PropTypes.string.isRequired,
//               color: PropTypes.string.isRequired,
//             })
//           ).isRequired,
//           backgroundImage: PropTypes.string.isRequired,
//           description: PropTypes.string.isRequired,
//           buttonLabel: PropTypes.string.isRequired,
//           buttonColor: PropTypes.string.isRequired,
//           buttonPath: PropTypes.string.isRequired,
//           styles: PropTypes.string.isRequired
//         }).isRequired,
//         mobile: PropTypes.shape({
//           title: PropTypes.arrayOf(
//             PropTypes.shape({
//               fragment: PropTypes.string.isRequired,
//               color: PropTypes.string.isRequired,
//             })
//           ).isRequired,
//           backgroundVideo: PropTypes.string.isRequired,
//           description: PropTypes.string.isRequired,
//           buttonLabel: PropTypes.string.isRequired,
//           buttonColor: PropTypes.string.isRequired,
//           buttonPath: PropTypes.string.isRequired,
//           styles: PropTypes.string.isRequired
//         }).isRequired
//       }).isRequired
//     })
//   }).isRequired
// };

const SubServicesPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const carouselData = post.frontmatter.subServices.commercialAnalytics.salientAnalytics.content.map(item => ({
    caption: item.caption,
    title: item.title,
    description: item.description,
    button: item.button,
    image: getImage(item.image),
    screen1: getImage(item.screen1),
    screen2: getImage(item.screen2),
    screen3: getImage(item.screen3),
    backgroundImage: getImage(item.backgroundImage),
    backgroundColor: item.backgroundColor,
  }));
  const salientData = {
    title: post.frontmatter.subServices.commercialAnalytics.salientFeatures.title,
    features: post.frontmatter.subServices.commercialAnalytics.salientFeatures.features.map(feature => ({
      caption: feature.caption,
      image: getImage(feature.image),
      backgroundColor: feature.backgroundColor
    })),
  };

  const pageIntroData = post.frontmatter.subServices.commercialAnalytics.pageIntroData;
  return (
    <Layout>
    <SubServicesPageTemplate
     pageIntroData={pageIntroData}
     salientData={salientData}
     carouselData={carouselData}
    />
  </Layout>
);
};

SubServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default SubServicesPage;

export const pageQuery = graphql`
  query SubServicesPage {
    markdownRemark(frontmatter: { templateKey: { eq: "sub-services" } }) {
      frontmatter {
        subServices {
          commercialAnalytics {
            pageIntroData {
              desktop {
                title {
                  fragment
                  color
                }
                backgroundVideo
                description
                buttonLabel
                buttonColor
                buttonPath
                styles {
                  sectionHeight
                  titleFontSize
                  descriptionFontSize
                  contentWidth
                  contentPadding
                }
              }
              mobile {
                title {
                  fragment
                  color
                }
                backgroundImage
                description
                buttonLabel
                buttonColor
                buttonPath
                styles {
                  sectionHeight
                  titleFontSize
                  descriptionFontSize
                  contentWidth
                  contentPadding
                }
              }
            }
          salientFeatures {
            title {
              fragment
              color
            }    
            features {
              caption
              image {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED width: 50 )
                  }
              }
              backgroundColor
            }
          }
          salientAnalytics {
              content {
                caption
                title
                description
                button
                image {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED width: 25 )
                  }
                }
                screen1 {
                  childImageSharp {
                    gatsbyImageData(width: 4000 )
                  }
                }
                screen2 {
                  childImageSharp {
                    gatsbyImageData(width: 3000 )
                  }
                }
                screen3 {
                  childImageSharp {
                    gatsbyImageData(width: 1000 )
                  }
                }
                backgroundImage {
                  childImageSharp {
                    gatsbyImageData(width: 600  )
                  }
                }
                backgroundColor
              }
            }
          }
          dataManagement {
            pageIntroData {
              desktop {
                title {
                  fragment
                  color
                }
                backgroundVideo
                description
                buttonLabel
                buttonColor
                buttonPath
                styles {
                  sectionHeight
                  titleFontSize
                  descriptionFontSize
                  contentWidth
                  contentPadding
                }
              }
              mobile {
                title {
                  fragment
                  color
                }
                backgroundImage
                description
                buttonLabel
                buttonColor
                buttonPath
                styles {
                  sectionHeight
                  titleFontSize
                  descriptionFontSize
                  contentWidth
                  contentPadding
                }
              }
            }
          }
        }
      }
    }
  }
`;
